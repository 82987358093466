import moment from 'moment'
import VoyageInfo from "../../voyageInfo";
import NullData from "../../nullData";
import Upload from "../../upload";
import refundOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_refundOrder";
import { ChangeDateFormat } from "@/lib/utils/common";
import saveRefundOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_saveRefundOrder";
import ChangeClause from "@/page/admin/travel-book/flight/component/changeClause/1.0.0/index.vue";
import consumer_air_ticket_getFlightRulesList from '@/lib/data-service/haolv-default/consumer_air_ticket_getFlightRulesList'
import consumer_air_ticket_flightRules from '@/lib/common-service/consumer_air_ticket_flightRules'
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
import ChangeReturnClausePopup from "@/page/admin/travel-book/flight/component/changeReturnClausePopup/1.0.0/index.vue"; // 获取退改规则
export default {
    data() {
        var validateRemark = (rule, value, callback) => {
            if (value.length < 5) {
                callback(new Error('请填写具体说明5到80字'));
            } else {
                callback();
            }
        };
        return {
            detail: {},
            noPassenger: true,
            segmentList: {},
            ChangeDateFormat: ChangeDateFormat,
            formData: {
                code: '',
                refReason: '',
                explainMsg: '',
                remarkText: '',
                remarkText1: '',
                url: "",
                orderStatus: 1,
                otherRefundReason: ''
            },
            rules: {
                code: [
                    { required: true, message: '请输入退票原因', trigger: 'blur' },
                ],
                remark: [
                    { required: true, message: '请输入退票原因', trigger: 'blur' },
                    { validator: validateRemark, trigger: 'blur' }
                ]
            },
            loading: false,
            canSubmit: true,
            dialogVisible:  false,
            ruleText: {},
            refundAmount: '',
            errInfo: {},
            imgUrl: [],
            refReasonEnum: [],
            ruleShow: false,
            ruleLoading: false,
            ruleTextList: [], // 退改规则
        }
    },
    components: {
        VoyageInfo,
        NullData,
        ChangeClause,
        Upload,
        ChangeReturnClausePopup
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getRefReasonEnum();
        this.react();
        this.formData.orderStatus = this.$route.query.orderStatus
        this.getDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        imgUrl(val) {
            this.formData.url = val.join(",");
        }
    },
    computed: {},
    filters: {
        formatTime(val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
        },
    },
    methods: {
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 3}).then(res=>{
                let refReasonEnum = res.datas;
                refReasonEnum.forEach(value=>{
                    let text = '';
                    if (value.reasonType === 1) {
                        text+= '个人原因：'
                    }
                    if (value.reasonType === 2) {
                        text+= '航司原因：'
                    }
                    text+= value.msg;
                    text+=value.refReason === 1 ? '（自愿退票）' : '（非自愿退票）'
                    console.log(text);
                    value.text = text;
                })
                this.refReasonEnum = refReasonEnum;
            })
        },
        react() {
            this.formData = {
                code: '',
                refReason: '',
                explainMsg: '',
                remarkText: '',
                remarkText1: '',
                url: "",
                orderStatus: 1,
                otherRefundReason: ''
            }
            this.segmentList = {}
            this.imgUrl = []
            this.refundAmount = ''
        },
        checkForm() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate((valid) => {
                    resolve(valid)
                })
            })
        },
        async submit() {
            let checkResult = await this.checkForm()
            if (!checkResult) {
                return
            }
            let segment = Object.values(this.segmentList);
            let formData = JSON.parse(JSON.stringify(this.formData));
            if (segment.length === 0)
                return this.$message({ type: "warning", message: "请选择航程" });
            if (formData.refReason !== 1 && formData.url === '')
                return this.$message({ type: "warning", message: "请上传证明" });
            let segmentTypeList = [];
            for (let key in this.segmentList) {
                this.segmentList[key].forEach(value=>{
                    if (segmentTypeList.indexOf(value.segmentType) === -1) {
                        segmentTypeList.push(value.segmentType)
                    }
                })
            }
            if (segmentTypeList.length > 1) {
                return this.$message({ type: "warning", message: "去程和回程不能同时选择" });
            }
            /**params */
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false
            formData.psgNo = segment.map(item => {
                return item.map(items => items.psgNo).join("");
            });
            formData.flightNo = segment.map(item => {
                return item.map(items => items.flightNo).join("");
            });
            formData.segmentNo = segment.map(item => {
                return item.map(items => items.segmentNo).join("");
            });

            let obj = {
                orderNo: this.detail.orderNo,
                evectionNo: this.detail.evectionNo,
                evectionType: this.detail.evectionType
            };
            const data = Object.assign(formData, obj);
            let params = {
                applet: data
            };
            saveRefundOrder(params).then(res => {
                this.canSubmit = true
                this.$message({ type: "success", message: "提交退票成功！" });
                this.$router.push({ name: "admin-my-order-flight-refundOrder" });
            }).catch(() => {
                this.canSubmit = true
            });
        },
        getSegment(val, index) {
            console.log(val, index)
            if (val.length > 0) {
                let segmentList = JSON.parse(JSON.stringify(this.segmentList))
                segmentList[index] = val;
                this.segmentList = segmentList
                console.log(this.segmentList)

            } else {
                delete(this.segmentList[index])
            }
            let segmentLength = 0;
            let psgNos = [];
            let cabin = '';
            let flightNo = '';
            let depDate = '';
            let depTime = '';
            for(let i in this.segmentList) {
                segmentLength = i + 1;
                this.segmentList[i].forEach(value => {
                    if (psgNos.indexOf(value.psgNo) === -1) {
                        psgNos.push(value.psgNo)
                    }
                    cabin = value.cabin;
                    flightNo = value.flightNo;
                    depDate = value.depDate;
                    depTime = value.depTime;
                });
            }
            if (segmentLength > 0) {
                let params = {
                    cabinCode: cabin,
                    flightNo: flightNo,
                    orderNo: this.$route.query.orderNo,
                    dateTime: `${depDate} ${depTime}`,
                    psgNos,
                    refReason: this.formData.refReason
                }
                consumer_air_ticket_getFlightRulesList(params).then(res => {
                    this.refundAmount = res.datas.refundAmount
                })
            } else {
                this.refundAmount = 0
            }

        },
        changeRefReason(val) {
            console.log(val, this.segmentList)
            this.refReasonEnum.forEach(value => {
                if (value.code === val) {
                    this.formData.refReason = value.refReason;
                    this.formData.refReasonText = value.refReason === 1 ? '自愿退票' : '非自愿退票';
                    this.formData.code = value.code;
                    this.formData.remarkText = value.msg;
                    this.formData.remarkText1 = value.msg + '（' + this.formData.refReasonText + '）';
                }
            })

            // 计算
            let segmentLength = 0;
            let psgNos = [];
            let cabin = '';
            let flightNo = '';
            let depDate = '';
            let depTime = '';
            for(let i in this.segmentList) {
                segmentLength = i + 1;
                this.segmentList[i].forEach(value => {
                    if (psgNos.indexOf(value.psgNo) === -1) {
                        psgNos.push(value.psgNo)
                    }
                    cabin = value.cabin;
                    flightNo = value.flightNo;
                    depDate = value.depDate;
                    depTime = value.depTime;
                });
            }
            if (segmentLength > 0) {
                let params = {
                    cabinCode: cabin,
                    flightNo: flightNo,
                    orderNo: this.$route.query.orderNo,
                    dateTime: `${depDate} ${depTime}`,
                    psgNos,
                    refReason: this.formData.refReason
                };
                consumer_air_ticket_getFlightRulesList(params).then(res => {
                    this.refundAmount = res.datas.refundAmount
                })
            } else {
                this.refundAmount = 0
            }
        },
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            let orderStatus = this.$route.query.orderStatus
            this.loading = true;
            refundOrder({ orderNo, orderStatus }).then(res => {
                this.loading = false
                let noPassenger = true
                let cabinCode = ''
                let flightNo = ''
                let dateTime = ''
                let segmentNo = ''
                let detail = res.orderRefund
                if (detail.airplanePassengerList) {
                    detail.airplanePassengerList.forEach(value => {
                        if (value.theFlightList && value.theFlightList.length > 0) {
                            value.theFlightList.forEach(val => {
                                val.psgNo = value.psgNo
                                val.ticketNumber = value.ticketNumber

                                if (cabinCode === '') {
                                    cabinCode = val.cabin
                                    flightNo = val.flightNo
                                    dateTime = `${val.depDate} ${val.depTime}`
                                    segmentNo = val.segmentNo
                                }
                            })
                            noPassenger = false
                        }
                    })
                }
                this.detail = detail
                this.noPassenger = noPassenger
                if (this.noPassenger) {
                    this.errInfo = {
                        msg: '该订单无可退票航班'
                    }
                    this.dialogVisible = true
                } else {
                    // 加载退改规则
                    this.ruleText = {}
                    let data = {
                        segmentNo: segmentNo
                    }
                    consumer_air_ticket_flightRules(data).then(res => {
                        this.ruleText = res.datas.rsData
                    })
                    let params = {
                        cabinCode,
                        flightNo,
                        orderNo,
                        dateTime
                    }
                    consumer_air_ticket_getFlightRulesList(params).then(res => {
                        this.refundAmount = res.datas.refundAmount
                    })
                }
            }).catch((err) => {
                this.loading = false;
                console.log('111', err)
                this.errInfo = err.data
                this.dialogVisible = true
            });
        },
        goBack() {
            this.dialogVisible = false
            // 9583 【差旅PC】机票订单列表-已退票的订单，点击申请退票按钮，页面先提示已无可退订单后，进入了退款页面
            this.$router.go(-1)
            /*if (this.errInfo.code === '000623') {
              let reiOrderNo = this.errInfo.orderRefund.reiOorderNo
              this.$router.replace({
                name: 'admin-my-order-flight-refund-form-detail',
                query: {
                  orderNo: reiOrderNo
                }
              })
            }*/
        },
        backRule() {
            this.ruleTextList = []
            this.ruleShow = true;
            let segmentNoList = [];
            let segmentApiList = [];
            this.detail.airplanePassengerList.forEach(value=>{
                value.theFlightList.forEach(value1 => {
                    if (segmentNoList.indexOf(value1.segmentNo) == -1) {
                        segmentNoList.push(value1.segmentNo);
                        segmentApiList.push(consumer_air_ticket_flightRules({
                            segmentNo: value1.segmentNo,
                        }))
                    }
                })
            })
            Promise.all(segmentApiList).then(res=>{
                let ruleTextList = [];
                res.forEach(value => {
                    ruleTextList.push(value.datas.rsData);
                })
                this.ruleTextList = ruleTextList;
                this.ruleLoading = false;
            })
        },
    }
}
